import styled, { css } from 'styled-components'
import { Media } from '../theme'

const SwiperCss = css`
  ${import('swiper/swiper.min.css')}
  ${import('swiper/swiper-bundle.min.css')}
  ${import('swiper/components/effect-coverflow/effect-coverflow.min.css')}
`

export const Banner = styled.div`
  ${SwiperCss};
  width: calc(100% - 164px);
  margin: auto;
  position: relative;
  height: 80vh;
  ${Media.phone`
    width: calc(100% - 20px);
    height: 470px;
  `}
  .swiper-wrapper {
    margin-bottom: 31px;
  }
  .swiper-slide {
    position: relative;
    height: calc(100% - 31px);
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 60px;
    height: 120px;
    background-color: rgba(19, 20, 21, 1);
    opacity: 0.4;
    top: 40%;
    :hover {
      background: rgba(43, 191, 165, 1);
      opacity: 0.6;
    }
    ::before,
    ::after {
      content: ' ';
      height: 41px;
      border-right: 3px solid;
      color: rgba(255, 255, 255, 1);
    }
    @media (max-width: 750px) {
      width: 25px;
      height: 60px;
      &.swiper-button-prev {
        left: 0;
      }
      &.swiper-button-next {
        right: 0;
      }
      ::before,
      ::after {
        height: 21px;
      }
    }
  }
  .swiper-button-prev ::before {
    transform: rotate(40deg) translateY(-12px) translateX(-8px);
    @media (max-width: 750px) {
      transform: rotate(40deg) translateY(-6px) translateX(-3px);
    }
  }
  .swiper-button-prev ::after {
    transform: rotate(147deg) translateY(-13px) translateX(13px);
    @media (max-width: 750px) {
      transform: rotate(147deg) translateY(-7px) translateX(7px);
    }
  }
  .swiper-button-next ::after {
    transform: rotate(40deg) translateY(16px) translateX(10px);
    @media (max-width: 750px) {
      transform: rotate(40deg) translateY(9px) translateX(6px);
    }
  }
  .swiper-button-next ::before {
    transform: rotate(147deg) translateY(10px) translateX(-9px);
    @media (max-width: 750px) {
      transform: rotate(147deg) translateY(3px) translateX(-5px);
    }
  }
  .swiper-pagination {
    position: absolute;
    z-index: 3;
    bottom: 0;
    display: flex;
    left: 50%;
    width: fit-content;
    transform: translate(-50%, 0);
    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
      background: #d9e4eb;
      opacity: 0.6;
      border-radius: 50%;
      margin: 0 15px;
      cursor: pointer;
      transition: all 0.5s;
      ${Media.phone`
        width: 12px;
        height: 12px;
      `}
    }
    .swiper-pagination-bullet-active {
      width: 47px;
      height: 16px;
      background: #02a6bc;
      border-radius: 8px;
      transition: all 0.5s;
      ${Media.phone`
        width: 35px;
        border-radius: 6px;
        height: 12px;
      `}
    }
  }
`
export const BannerBg = styled.img`
  object-position: ${(p) => p.objectPosition && p.objectPosition};
`

export const ImgBox = styled.div`
  position: relative;
  width: auto;
  height: 80vh;
  ${BannerBg} {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 10px;
    ${Media.phone`
      border-radius: 7px;
    `}
  }
  ${Media.phone`
    height: calc(100vh - 210px);
  `}
`
export const TextBox = styled.div`
  position: absolute;
  bottom: 10%;
  text-align: left;
  padding-left: 36px;
  width: 82%;
  ${Media.phone`
    padding: 0 15px;
    bottom: 20px;
    width: 100%;
  `}
`
export const PicTitle = styled.h1`
  font-size: 48px;
  font-family: Source Han Sans CN, Source Han Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
  ${Media.phone`
    font-size: 24px;
    line-height: 23px;
  `}
`
export const SubTitle = styled.h2`
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  margin: 16px 0;
  ${Media.phone`
    font-size: 12px;
    margin: 10px 0 7px 0;
    line-height: 13px;
  `}
`
export const PicContent = styled.div`
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  ${Media.phone`
    font-size: 10px;
    line-height: 15px;
  `}
`
export const CaseArrow = styled.img`
  position: absolute;
  bottom: 90px;
  right: 90px;
  ${Media.phone`
    display: none;
  `}
`
