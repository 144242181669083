import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useIntl } from 'gatsby-plugin-react-intl'
import SwiperCore, { Pagination, Navigation, Autoplay, EffectCoverflow } from 'swiper/core'
import { LangType } from '../../constant'
import {
  Banner,
  PicTitle,
  PicContent,
  SubTitle,
  ImgBox,
  TextBox,
  CaseArrow,
  BannerBg,
} from '../../style/case/case-banner.atom'
import Hublot from '../../images/case/hublot.png'
import Chang from '../../images/case/chang.png'
import LLEarth from '../../images/case/lldq.png'
import Neza from '../../images/case/neza.png'
import Robots from '../../images/case/robots.png'
import Xiong from '../../images/case/xiong.png'
import Baahubali from '../../images/case/baahubali.png'
import Ejenali from '../../images/case/ejenali.png'
import Earth2 from '../../images/case/earth2.png'
import Xssn from '../../images/case/xssn.png'
import ThreeBody from '../../images/case/threebody.png'
import CaseArrowImg from '../../images/case/case_arrow.png'

const PicList = [
  {
    alt: '长津湖',
    src: Chang,
    title: '长津湖',
    subTitle: '',
    content:
      '《长津湖》以抗美援朝战争第二次战役中的长津湖战役为背景，讲述了一段波澜壮阔的历史：71年前，中国人民志愿军赴朝作战，在极寒严酷环境下，东线作战部队凭着钢铁意志和英勇无畏的战斗精神一路追击，奋勇杀敌，扭转了战场态势，打出了军威国威。',
    intl: LangType.CN,
  },
  {
    alt: '流浪地球',
    src: LLEarth,
    title: '流浪地球',
    subTitle: '中国首部科幻巨作,第9届北京国际电影节天坛奖',
    content:
      '国内上映二十天，票房不断飙升突破43亿人民币，成为中国电影史票房亚军。它的出现，提升中国电影工业的新高度，弥补了在科幻题材的空白，为中国科幻电影照亮了前行的道路，开启中国科幻电影元年',
    intl: LangType.CN,
    objectPosition: '10%',
  },
  {
    alt: '三个机器人',
    src: Robots,
    title: '三个机器人',
    subTitle: '《死侍》导演蒂姆·米勒与《纸牌屋》的导演大卫·芬奇联手监制',
    content:
      '“《三个机器人》这个项目与瑞云合作，让我喜出望外。瑞云渲染服务可以使我们在规定的期限内完成制作并交付。如果有项目上的需要，我们肯定会再次与他们合作。”——西班牙动画工作室Blow Studio',
    intl: LangType.CN,
  },
  {
    alt: '哪吒之魔童降世',
    src: Neza,
    title: '哪吒之魔童降世',
    subTitle: 'IMAX 3D首部国产动画电影刷新中国动画电影票房纪录',
    content:
      '首映期间创动画电影最快破亿纪录、动画电影首日最高票房纪录、国产动画电影单周票房新纪录...“我是小妖怪，逍遥又自在。杀人不眨眼，吃人不放盐。”黑眼圈、小雀斑、鲨鱼牙、厌世脸...颠覆传统莲花化身，没有一丝仙气儿的熊孩子“丑哪吒“，在电影点映期间从无人问津到口碑爆棚，让人看到国漫崛起的新标准，新希望！',
    intl: LangType.CN,
  },
  {
    alt: '流浪地球2',
    src: Earth2,
    title: '流浪地球2',
    subTitle: '',
    content:
      '影片围绕前作《流浪地球》之前的故事展开，太阳即将毁灭，人类在地球表面建造出巨大的推进器，寻找新的家园。然而宇宙之路危机四伏，为了拯救地球，流浪地球时代的年轻人再次挺身而出，展开争分夺秒的生死之战。',
    intl: LangType.CN,
    objectPosition: '20%',
  },
  {
    alt: '熊出没·伴我“熊芯”',
    src: Xiong,
    title: '熊出没·伴我“熊芯”',
    subTitle: '',
    content:
      '一个普通的森林夜晚，对小熊大、小熊二宠爱有加的熊妈妈，在一场大火后离开了他们，两熊伤心不已……转眼多年过去，光头强带熊大熊二前往振兴岛参观机器人研究所，却意外得到了熊妈妈的线索，为此熊大、熊二一路探寻……熊妈当年为何不告而别？两熊最终是否能找到妈妈？迷雾重重的背后还有怎样的故事？一切谜团等待揭晓。',
    intl: LangType.CN,
    objectPosition: '1%',
  },
  {
    alt: '雄狮少年',
    src: Xssn,
    title: '雄狮少年',
    subTitle: '',
    content:
      '由《包强系列动画》《美食大冒险》导演孙海鹏执导，《风犬少年的天空》编剧里则林，影片聚焦佛山舞狮，讲述了留守少年阿娟和好友阿猫、阿狗在退役狮王咸鱼强的培训下参加舞狮比赛，经过重重磨砺，从病猫变成雄狮的成长故事。',
    intl: LangType.CN,
    objectPosition: '12%',
  },
  {
    alt: '哈布洛先生(Mr. Hublot)',
    src: Hublot,
    title: '哈布洛先生(Mr. Hublot)',
    subTitle: '卢森堡3D动画短片，长11分钟，2014年第86届奥斯卡最佳动画短片奖。',
    content:
      '“作为一家在法国的3D动画电影的小型工作室，我们需要大量的运算力来展现我们的创意，这需要比工作室多得多的电脑，在没有任何外界帮助下，计算机渲染这部分难以完成，最终我们找到了瑞云。”---短片导演 劳伦特维茨(Laurent Witz)',
    intl: LangType.CN,
    objectPosition: '30%',
  },
  {
    alt: '《三体》电视剧',
    src: ThreeBody,
    title: '《三体》电视剧',
    subTitle: '根据著名作家刘慈欣同名科幻小说《三体》改编',
    content:
      '该剧讲述了地球基础科学研究遭遇异常扰动，引发科学界惶恐动荡，纳米物理学家汪淼与刑警史强联手调查，共同揭开了地外未知文明“三体”',
    intl: LangType.CN,
  },
  {
    alt: 'Baahubali 2: The Conclusion',
    src: Baahubali,
    title: 'Baahubali 2: The Conclusion',
    subTitle: 'Amazing Fantasy Epic Film, The Most Popular Indian Movie in History ',
    content:
      '“This is a fantasy fairy tale kind of movie where India introduces its culture to the world. Visual brilliance, perfect portray of emotions and showcasing of Indian culture makes it worth watching!” - Hollywood Critic Grace Randolph',
    intl: LangType.EN,
  },
  {
    alt: 'The Wandering Earth',
    src: LLEarth,
    title: 'The Wandering Earth',
    subTitle:
      "China's first homemade Sci-Fi blockbuster; The 9th Beijing International Film Festival Tiantan Award",
    content:
      "As China's first homemade Sci-Fi blockbuster, it raised the bar for China's film industry, lighted up the future of Chinese Sci-Fi movies and created a new era for Chinese science fiction.",
    intl: LangType.EN,
  },
  {
    alt: 'Three Robots',
    src: Robots,
    title: 'Three Robots',
    subTitle:
      "Episode 2 of  Love, Death & Robots, David Fincher & Tim Miller's Animated Netflix Anthology Series",
    content:
      'It was a pleasant surprise to work with Fox Renderfarm. We had to outsource some parts of the render in order to deliver on time and Fox Renderfarm allowed us to meet our deadline. Sure we will work again with them if we need to.” - Spanish animation studio Blow Studio.',
    intl: LangType.EN,
  },
  {
    alt: 'Ne Zha',
    src: Neza,
    title: 'Ne Zha',
    subTitle: 'Highest-grossing animated film of all time in China',
    content:
      'Nezha is a 2019 animated feature directed by Yang Yu, also known as Jiaozi. Nezha breaks numerous records, ranking among the Top 3 highest-grossing movies and as the NO.1 animated film in China, 2019, also generates buzz and praise home and abroad.',
    intl: LangType.EN,
  },
  {
    alt: 'Ejen Ali: The Movie',
    src: Ejenali,
    title: 'Ejen Ali: The Movie',
    subTitle: 'Highest-grossing local animated film ever in Malaysia',
    content:
      '"When we first tried Fox Renderfarm, the speed was just amazing. We started working with Fox Renderfarm during our second season, I think; halfway to the second season. And since then, we knew for certain, we want to be working with Fox Renderfarm for the movie. So far, no complaint, it has been amazing! The system that we get has been prompt. I think it was a good move and it was a very good decision for us to work with Fox Renderfarm. And I can’t imagine delivering the movie without working with Fox Renderfarm." - Usamah Zaid Yasin',
    intl: LangType.EN,
  },
  {
    alt: 'Mr. Hublot',
    src: Hublot,
    title: 'Mr. Hublot',
    subTitle:
      'Luxembourg 3D Animated Short Film (11 minutes), 86th Academy Awards (Oscars 2014) Best Animated Short Films',
    content:
      '“As a small studio of 3D animation film in France, we need a lot of computing power to show our originality. This requires a great number of computers than those used in the studio. Computer rendering cannot be finished without external support. Finally, we find Fox Renderfarm.” - Laurent Witz ',
    intl: LangType.EN,
  },
]

SwiperCore.use([Pagination, Navigation, Autoplay, EffectCoverflow])

const CaseBanner = () => {
  const intl = useIntl().locale

  return (
    <Banner>
      <Swiper
        navigation={true}
        pagination={{ clickable: true }}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1.3}
        loop={true}
        coverflowEffect={{
          rotate: -10,
          stretch: 200,
          depth: 500,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
      >
        {PicList.filter((p) => p.intl === intl).map((p) => {
          return (
            <SwiperSlide key={p.alt}>
              <ImgBox>
                <BannerBg alt={p.alt} src={p.src} objectPosition={p.objectPosition} />
                <TextBox>
                  <PicTitle>{p.title}</PicTitle>
                  <SubTitle>{p.subTitle}</SubTitle>
                  <PicContent>{p.content}</PicContent>
                </TextBox>
                <CaseArrow src={CaseArrowImg} />
              </ImgBox>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Banner>
  )
}

export default CaseBanner
