import styled from 'styled-components'
import { Media } from '../theme'

export const CaseSwiperContainer = styled.div`
  position: relative;
  padding-top: 70px;
  margin: auto;
  height: 100vh;
  text-align: center;
  ${Media.phone`
    padding-top: 60px;
  `}
`
