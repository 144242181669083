import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-react-intl'
import CaseBanner from '../components/case/case-banner'

import { Layout } from '../components/layout'
import Seo from '../components/seo'
import { CaseSwiperContainer } from '../style/pages/case.atom'

const CasePage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'case_seo.title' })}
        description={intl.formatMessage({ id: 'case_seo.description' })}
        keywords={intl.formatMessage({ id: 'case_seo.keywords' })}
      />
      <CaseSwiperContainer>
        <StaticImage
          className='background-image'
          layout='fullWidth'
          alt='case-background'
          src='../images/case/case-bg.png'
        />
        <CaseBanner />
      </CaseSwiperContainer>
    </Layout>
  )
}

export default CasePage
